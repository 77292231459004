import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';
import ScrollAnimation from 'react-animate-on-scroll';

import { ContentSection, HeroImage, Layout, Map, Seo, Text } from '../components';

import '../styles/Home.scss';


const IndexPage = () => {
  const { formatMessage } = useIntl();

  return (
    <Layout>
      <Seo title="ADC" />

      <div className="HeroWrapper">
        <div className="HeroImageWrapper">
          <HeroImage />

          <ScrollAnimation animateIn="fadeIn" duration={1.6}>
            <div className="HeroTextContainer">
              <h2 className="HeroTitle">{formatMessage({ id: 'ADC' })}</h2>
              <h3 className="HeroSubtitle">{formatMessage({ id: 'Audyt Doradztwo Controlling' })}</h3>
            </div>
          </ScrollAnimation>
        </div>
      </div>

      <ContentSection wrapperClassName="About" title={formatMessage({ id: 'About us' })} id="about">
        <Text>
          {formatMessage({ id: 'ADC Audyt Doradztwo Controlling sp. z o.o. was established in 2010. We are a modern and professional company that aims to respond to the growing needs of entrepreneurs. Since the very beginning of our business activity, the quality of the services we are rendering and a friendly atmosphere of work have always been our priorities. We are putting all possible effort to make sure that all we are doing meets the highest professional standards.' })}
        </Text>
        <Text>
          {formatMessage({ id: 'Our goal is to satisfy our customer needs and expectations. These principles shape our business relationships. All our clients benefit from our expertise and individual approach. A long term co-operation has always been supporting development and innovation that is so much appreciated by our business partners.' })}
        </Text>
        <Text>
          {formatMessage({ id: 'As a company, we are focused on our stakeholders, perceive and treat our customers as partners in business who help us to improve, develop and create added value in all aspects of our operations.' })}
        </Text>
      </ContentSection>

      <ContentSection wrapperClassName="Team" title={formatMessage({ id: 'Team' })} id="team">
        <Text>
          {formatMessage({ id: 'Our team consists of employees who have extensive knowledge and experience gained while working in finance, accounting, HR and payroll departments in businesses of different size, structure and operating in a variety of sectors. Our staff has many-year practice on managerial positions in companies with foreign capital and is well prepared to work in a challenging and dynamic business environment. Therefore, our customers can be sure they will be provided with a full range of high-quality services. We continuously encourage our clients to use our expertise to address any issues as soon as they arise.' })}
        </Text>
        <Text>
          {formatMessage({ id: 'Our certified accountants, experienced auditors, tax advisors and other consultants specialised in accounting, commercial and tax law work together to realise tasks and complete orders. We ensure good communication flow among our teams to enhance the quality of our services ranging from a basic up to a complex strategic advisory. We aim to be the first source of information and support for our clients.' })}
        </Text>
      </ContentSection>

      <ContentSection wrapperClassName="Services" title={formatMessage({ id: 'Services' })} id="services">
        <Text>
          {formatMessage({ id: 'We provide comprehensive services to all types of companies offering full bookkeeping and accounting (including financial reporting), simplified accounting (fixed tax, flat-rate tax, revenue and expense ledger), social insurance records and calculations, representing of our clients before authorities such as Inland Revenue, Central Statistical Office, Social Insurance Office, National Court Register and other. We always put all possible effort to help our customers in their needs and concerns.' })}
        </Text>
        <Text className="ServicesTitle">
          {formatMessage({ id: 'We have extensive experience not only in bookkeeping, accounting and payroll, but we provide advisory on:' })}
        </Text>

        <ScrollAnimation animateIn="zoomIn" duration={2}>
          <ul className="List">
            <li>{formatMessage({ id: 'economic, legal, financial issues' })},</li>
            <li>{formatMessage({ id: 'company valuation, acquisitions and investments' })},</li>
            <li>{formatMessage({ id: 'taxation' })},</li>
          </ul>

          <ul className="List">
            <li>{formatMessage({ id: 'organisation and digitalisation of accounting (developing a company’s accounting policies, workflow, manuals)' })},</li>
            <li>{formatMessage({ id: 'conduct a company’s liquidation process' })},</li>
            <li>{formatMessage({ id: 'prepare training in the areas of accounting, taxation and finance adjusted to a company’s needs' })}.</li>
          </ul>
        </ScrollAnimation>
      </ContentSection>

      <ContentSection wrapperClassName="Contact" title={formatMessage({ id: 'Contact' })} id="contact">
        <div className="Contact_Maps_Wrapper">
          <div className="MapColumnFirst">
            <h4>{formatMessage({ id: 'Gdynia Office' })}</h4>
            <h5>{formatMessage({ id: 'Strzelców street 50 place 9' })}</h5>
            <h5>81-586 Gdynia</h5>

            <Map
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2318.1120236373936!2d18.499989316012027!3d54.47860529699791!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fda0e3416f6ff7%3A0x1f57eb17034924b4!2sStrzelc%C3%B3w%2050%2C%2081-586%20Gdynia!5e0!3m2!1spl!2spl!4v1652905538282!5m2!1spl!2spl"
            />
          </div>

          <div  className="MapColumnSecond">
            <h4>{formatMessage({ id: 'Gdańsk Office' })}</h4>
            <h5>{formatMessage({ id: 'Jaśkowa Dolina 73C place 1A' })}</h5>
            <h5>80-286 Gdańsk</h5>

            <Map
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2324.35692088011!2d18.591237315814535!3d54.36834398020597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd74f33c1df42d%3A0xc4ad566fb5435c7f!2sJa%C5%9Bkowa%20Dolina%2073C%2C%2080-286%20Gda%C5%84sk!5e0!3m2!1spl!2spl!4v1594470236184!5m2!1spl!2spl"
            />
          </div>

          <div className="OfficialNumbersWrapper">
            <span>NIP: 5862262137</span>
            <span>REGON: 221136961</span>
            <span>KRS: 0000371766</span>
          </div>
        </div>

        <div className="EmailContactWrapper">
          <Text>
            {formatMessage({ id: 'Contact Us' })}
          </Text>

          <div className="DetailsWrapper">
            <div className="Column">
              <FontAwesomeIcon icon={faEnvelopeSquare} />
              <a href="mailto:a.lewkowicz@adc-adc.pl">a.lewkowicz@adc-adc.pl</a>
            </div>

            <div className="Column">
              <FontAwesomeIcon icon={faEnvelopeSquare} />
              <a href="mailto:w.lewkowicz@adc-adc.pl">w.lewkowicz@adc-adc.pl</a>
            </div>
          </div>
        </div>
      </ContentSection>

    </Layout>
  );
};

export default IndexPage;
